/**
 * Auth Model
 */

 export default class AuthModel {

    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
}
